import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import  About  from "./components/about";
import { Features } from "./components/features";
import { Services } from "./components/services";
import { Icligm } from "./components/icligm";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { Iclchat } from "./components/iclchat";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { Helmet } from "react-helmet";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
// Function to initialize Google Analytics
const initGoogleAnalytics = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-HFVSCEEVZ0');
  //  gtag('config', 'AW-1070405040');
};

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    initGoogleAnalytics();
    setLandingPageData(JsonData);
  }, []);

// Determine the title based on the current section or data
const getTitle = () => {
  let mtitle;
  
  // // Example: Customize based on the current section
  // if (landingPageData.currentSection === 'Features') {
  //   mtitle = landingPageData.Features.title ;
  // } else {
    mtitle = 'Eximon.com - Software, Database, Cloud and AI development services';
//  }
  
  return mtitle;
};

  return (
    <div>
      <Helmet>
        <title>{getTitle()}</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Eximon.com - Software, Database, Cloud and AI development services" data-react-helmet="true"/>
        <meta name="keywords" content="software, database, cloud, AI, ChatBot, Importers, Exporters, Shipping, Logistics, Maritime" />
        {/* Add other meta tags if needed */}
      </Helmet>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Icligm data={landingPageData.Icligm} />       
      <Iclchat data={landingPageData.Iclchat} />       
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    
    </div>
  );
};

export default App;
